
function Button(props) {
    var estilo = props.className + ' btn';
    var form = <>
    <div className="text-center mt-4">
        <button type={props.type} className={estilo} onClick={props.onClick}>{props.text}</button>
    </div>
    </>;
    return form
}

export default Button;