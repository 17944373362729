import React, { useState, useContext } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Login from './pages/login/index.jsx';
import Chat from './pages/chat/index.jsx';
import { AuthProvider, AuthContext } from "./contexts/auth.jsx";

function Rotas() {

    const Private = ({children}) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if(!authenticated) {
            return <Navigate to="/login" />;
        }
        return children;
    }

    const Public = ({children}) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if(authenticated) {
            return <Navigate to="/" />;
        }
        return children;
    }

    return  (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path='/' element={<Private><Chat /></Private>}/>
                    <Route path='/login' element={<Public><Login /></Public>}/>
                    <Route path='*' element={<Login />}/>
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Rotas;