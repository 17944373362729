function Input(props, { key }) {
    var estilo = props.className + ' form-control';
    var form = <>
    <div className="mb-3">
        <label htmlFor={props.id} className="form-label">{props.label}</label>
        <input type={props.type} 
            className={estilo} 
            placeholder={props.placeholder} 
            id={props.id} 
            name={props.name} 
            autoComplete={props.autoComplete}
            onChange={props.onChange}
            value={props.value}
            onKeyDown={props.onKeyDown} />
    </div>
    </>;
    return form
}

export default Input;