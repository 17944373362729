import axios from "axios";

export const api = axios.create({
    //baseURL : 'http://3.222.109.49:9001',
    // baseURL : 'http://localhost:9001',
    // baseURL : 'http://192.168.3.135:9001',
    baseURL : 'https://apisuporte.easysoftsistemas.com.br/',

});

export const createSession = async (email, password) => {
    return api.post('/sessions', {email, password});
}
export const buscaChamadoAbertoHistorico = async () => {
    return api.post('/chamado/aberto/historico');
}
export const buscaNovosChamadoAberto = async (idAberto) => {
    return api.post('/chamado/aberto', {idAberto});
}
export const buscaChamadoStatus = async (idSuporte) => {
    return api.post('/chamado/consulta', {idSuporte});
}
export const atribuirSuporte = async (idSuporte, idTecnico) => {
    return api.post('/chamado/atribuir', {idSuporte, idTecnico});
}
export const buscarNovasMensagens = async (suporte_id, mensagem_id, tecnico_id) => {
    return api.post('chamado/mensagens', {suporte_id, mensagem_id, tecnico_id});
}
export const enviarMensagem = async (cod_suporte, mensagem, cod_tecnico, enviado) => {
    return api.post('chamado/mensagem/enviar', {cod_suporte, mensagem, cod_tecnico, enviado});
}
export const enviarComentario = async (comentario, suporte_id, tecnico_id) => {
    return api.post('chamado/comentario', {comentario, suporte_id, tecnico_id});
}
export const buscaClientes = async () => {
    return api.post('consulta/clientes');
}
export const buscaClienteTelefone = async () => {
    return api.post('consulta/cliente-telefone');
}
export const alterarCliente = async (id, suporte_id) => {
    return api.post('chamado/cliente', {id, suporte_id});
}
export const encerrarSuporte = async (cod_suporte, status) => {
    return api.get('chamado/encerrar', { params: {cod_suporte, status} });
}
export const buscaTecnicos = async (t) => {
    return api.post('consulta/tecnicos', {t});
}
export const alterarTecnico = async (id, suporte_id) => {
    return api.post('chamado/tecnico', {id, suporte_id});
}
export const buscarHistoricoMensagens = async (suporte_id, tecnico_id) => {
    return api.post('chamado/mensagem/historico', {suporte_id, tecnico_id});
}
export const consultaNovasMensagens = async (suporte_id) => {
    return api.post('chamado/mensagens/lido', {suporte_id});
}
export const consultaCategoria1 = async () => {
    return api.post('consulta/categoria1');
}
export const consultaCategoria2 = async (id) => {
    return api.post('consulta/categoria2', {id});
}
export const consultaCategoria3 = async (id) => {
    return api.post('consulta/categoria3', {id});
}
export const alterarCategoria = async (id1, id2, id3, suporte_id) => {
    return api.post('chamado/categoria', {id1, id2, id3, suporte_id});
}
export const abrirChamadoWhatsapp = async (telefone, id_tecnico) => {
    return api.post('abrir-chamado/whatsapp', {telefone, id_tecnico});
}