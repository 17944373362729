import React from "react";
import ReactDOM from 'react-dom/client';
import "./assets/scss/theme.scss";
import Rotas from './rotas.jsx';

document.documentElement.style.setProperty("--bs-primary-rgb", "80,165,241");


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Rotas />
);

