
function Alert(props) {
    var estilo = 'alert alert-' + props.className + ' ' + props.visible;
    return <>
        <div className={estilo} role="alert">
            <i className="fa-solid fa-triangle-exclamation"></i> {props.text}
        </div>
    </>;
}

export default Alert;