import React, { useEffect, useState } from "react";
import './style.css';
import { api, consultaNovasMensagens } from "../../services/api";

function LeftBar_Item({ item, status, handlerClickedItem, user, play }) {

    const [naoLidas, setNaoLidas] = useState();

    var letras = "";
    const palavras = String(item.nomecliente).split(" ");

    for (let i = 0; i < palavras.length; i++) {
        letras += palavras[i][0];
    }
    letras = letras.substring(0, 2).toUpperCase();
    var background;
    var bolinha = "chat-user-img align-self-center me-2 ms-0 " + status;
    if (status === "online") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-alert";
    }
    else if (status === "offline") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-info";
    }
    else if (status === "away") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-danger";
    }

    useEffect(() => {
        verificaMensagemLido();
        let intervalId = setInterval(() => {
            verificaMensagemLido();
        }, 3500);
        return () => {
            clearInterval(intervalId);
        }
    }, []);



    const verificaMensagemLido = async () => {
        var element1 = document.getElementById(item.id);
        if ((item.status == "Em Atendimento") && (!element1.classList.contains('ativo'))) {
            const response = await consultaNovasMensagens(item.id);
            if (response.status === 200) {
                if (response.data.count > 0) {
                    element1.classList.add('bg-warninga');
                    setNaoLidas(response.data.count);

                    play();
                }
                else {
                    element1.classList.remove('bg-warninga');
                    setNaoLidas();
                }
            }
        }
    }
    const verificaCount = () => {
        if (item.tecnico_id == user.id) {
            setNaoLidas();
        }
    }

    var letra = '';
    var disabled_li = '';

    if (item.posicao > 1) {
        disabled_li = 'list-group-item-dark'
    }

    if (item.nota == 5) {
        letra = 'text-success'
    }
    else if (item.nota == 4) {
        letra = 'text-warning'
    }
    else if (item.nota >= 1 && item.nota <= 3) {
        letra = 'text-danger'
    }

    return (

        <li className={disabled_li} id={item.id} onClick={(e) => {
            if (item.posicao == 1 || item.status != "Em Aberto") {
                handlerClickedItem(item); verificaCount()
            }
        }}>

            <a className="">
                <div className="d-flex align-items-center">
                    <div className={bolinha}>
                        <div className="avatar-xs">
                            <span className={background}>
                                <span className="username"> {letras}</span>
                                <span className="user-status"></span>
                            </span>
                        </div>
                    </div>
                    <div title={item.nomecliente} className="overflow-hidden" style={{ width: "100vh" }}>
                        <p className={"text-truncate mb-0 " + letra}>{item.nomecliente}</p>
                        <div className="row container-flex">
                            <div className="col-md-9">
                                <p
                                    className="text-truncate mb-0"
                                    style={{
                                        fontSize: 10,
                                        color: item.tecnicon === "ANDREIA GODKE" ? "red" : "inherit"
                                    }}
                                >
                                    {item.operador} - {item.tecnicon}
                                </p>
                            </div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <p className="mb-0" style={{ fontSize: 10 }}>{item.dataabertura}&nbsp;&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="ms-auto">
                        <span className="badge badge-soft-danger rounded p-1">
                            {naoLidas}
                        </span>
                    </div>
                </div>
            </a>
        </li>
    );
}
export default LeftBar_Item;