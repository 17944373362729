import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import './style.css';

import authImage from '../../assets/img/auth-img.png';
import Input from '../../components/input/index.jsx';
import Button from '../../components/button/index.jsx';
import Alert from '../../components/alert/index.jsx';

function Login() {

    const { authenticated, login, error} = useContext(AuthContext);

    var visible = "";
    if (error === "") {
        var visible = "visually-hidden";
    }
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    }; 
    const handlerKeyPress = (e) => {
        
        var k = e.keyCode;
        if(k === 13){
            e.preventDefault();
            document.getElementById('senha').focus();
        }
    }

    return <div className='auth-bg'>
        <div className='p-0 container-fluid'>
            <div className="row login">
                <div className="col-lg-4 col-xl-3">
                    <div className='p-4 pb-0 p-lg-5 pb-lg-0 auth-logo-section'>
                        <div style={{marginLeft: "48px"}} className='text-white-50'>
                            <h3>
                                <a className="text-white">
                                    <i className="bx bxs-message-alt-detail align-middle text-white h3 mb-1 me-2"></i> Central de atendimento
                                </a>
                            </h3>
                            <p className='font-size-16'>Acesso a plataforma de Atendimento EasySoft</p>
                        </div>
                        <div className='mt-auto'>
                            <img src={authImage} alt="auth" className="auth-img" />
                        </div>
                    </div>
                    
                </div>
                <div className="col-lg-8 col-xl-9">
                    <div className='authentication-page-content'>
                        <div className='d-flex flex-column h-100 px-4 pt-4'>
                            <div className=' justify-content-center my-auto row'>
                                <div className='col-xxl-4 col-sm-8 col-lg-6 col-xl-5'>
                                    <div className='className="py-md-5 py-4'>
                                        <div className='text-center mb-5'>      
                                            <h3>Olá !</h3>
                                            <p className="text-muted">Para acessar o suporte entre com seu código e senha</p>                              
                                        </div>    
                                        {<Alert text={error} className="danger" visible={visible} />}
                                        <form className='position-relative' onSubmit={handleSubmit}>
                                            <Input 
                                                type="number" 
                                                label="Código" 
                                                placeholder="Insira seu código" 
                                                name="codigo" 
                                                id="codigo" 
                                                autoComplete="codigo" 
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                onKeyDown={(e) => handlerKeyPress(e)}
                                                key="codigo" />
                                            <Input 
                                                type="password" 
                                                label="Senha" 
                                                placeholder="Insira sua senha" 
                                                className="pe-5" 
                                                name="senha" 
                                                id="senha" 
                                                autoComplete="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                key="senha" />
                                            <Button 
                                                type="submit" 
                                                className="btn-primary w-100" 
                                                text="Entrar"/>
                                        </form>
                                    </div>
                                </div>    
                            </div>
                            <div className="row"><div className="col-xl-12"><div className="text-center text-muted p-4"><p className="mb-0">© 2022 EasySoft Sistemas</p></div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Login;